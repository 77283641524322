<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 17:49:59
 * @LastEditTime: 2021-09-09 10:09:35
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \vote\components\vote-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :append-to-body="true" :visible.sync="openDialog">
    <layout-filter
      :onFilter="onFilter"
      :onReset="onReset"
      :toggleCollapseEnable="false"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-wvalueth="70px"
        size="medium"
        class="filter-from"
        @submit.native.prevent
      >
        <div class="filter-item">
          <el-form-item label="资讯分类：" prop="category_name">
            <el-select
              v-model="form.category_name"
              placeholder="请选择资讯分类"
            >
              <el-option
                v-for="item in category"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问题状态：" prop="question_status">
            <el-select
              v-model="form.question_status"
              placeholder="请选择问题状态"
            >
              <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="filter-item">
          <DatePeriodPicker
            label="创建时间："
            start-placeholder="开始日期"
            :start-time.sync="form.create_start_time"
            start-prop="create_start_time"
            end-placeholder="结束日期"
            :end-time.sync="form.create_end_time"
            end-prop="create_end_time"
          />
        </div>
      </el-form>
    </layout-filter>
  </el-dialog>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { categories } from '@/modules/consult/api/consult-service-workbench'
export default {
  name: 'RecordsFilterDialog',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    open: {
      type: Boolean,
      default: false,
    },
    recordsForm:{
        type: Object,
        default: () => ({})
    }
  },
  data() {
    return {
      form: {
        category_name: '', //资讯分类名
        question_status: -1, // -1 全部，0:未解决，1:已解决
        create_start_time: -1,
        create_end_time: -1,
      },
      rules: {},
      status: [
        {
          name: '全部',
          value: -1,
        },
        {
          value: 3,
          name: '未解决',
        },
        {
          value: 1,
          name: '已解决',
        },
      ],
      category: [],
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = this.recordsForm[key]
        })
        console.log(this.form);
        categories().then((res) => {
          this.category = res.data
        })
      }
    },
  },
  created() {},
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form))
    },
    //重置
    onReset() {
      this.$refs['ruleForm'].resetFields()
      this.onFilter()
    },
    //导出
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
<style lang="scss" scoped></style>
