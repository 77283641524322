<template>
  <div class="divider" style="width: 100%">
    <el-divider>{{ content.text }}</el-divider>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>

<style lang="scss" scoped>
.divider {
  ::v-deep.el-divider {
    .el-divider__text {
      background-color: rgba(248, 249, 251, 1);
    }
  }
}
</style>
