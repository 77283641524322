<template>
  <div class="message-list" :style="[customStyle]" ref="messageListRefs" @scroll="messageScroll">
    <div
      v-show="!message.noMore"
      style="height: 50px"
      element-loading-spinner="el-icon-loading"
      element-loading-background="transparent"
      v-loading="message.loading"
    ></div>

    <div v-for="item in message.list" :key="item.id">
      <div
        class="message-box"
        :class="item.is_my !== 1 ? 'message-left' : 'message-right'"
      >
        <!-- 已经撤回消息 -->
        <div class="withdraw" v-if="item.is_withdrew === 1">
          <!-- 我撤回的消息 -->
          <div v-if="item.is_my === 1">
            你已经撤回一条消息
            <text
              v-if="item.type === 'text' && item.content !== null"
              @click="reedit(item)"
            >
              重新编辑
            </text>
          </div>
          <!-- 对方撤回的消息 -->
          <div v-else>对方已经撤回一条消息</div>
        </div>
        <!-- 提示消息 -->
        <tipsMsg :content="item.content" v-else-if="item.type === 'finished'" />
        <template v-else>
          <div class="avatar">
            <!-- 右边头像 -->
            <el-image
              v-if="item.is_my === 1"
              style="
                width: 32px;
                height: 32px;
                border-radius: 999rem;
                margin-left: 12px;
              "
              :src="rightAvatar"
              fit="cover"
            ></el-image>
            <!-- 左边头像 -->
            <el-image
              v-else
              style="
                width: 32px;
                height: 32px;
                border-radius: 999rem;
                margin-right: 12px;
              "
              :src="leftAvatar"
              fit="cover"
            ></el-image>
          </div>

          <div class="message-content">
            <div class="time">
              {{ toggleTime(item.create_time) }}
            </div>
            <!-- 文字 -->
            <div v-if="item.type == 'text' || item.type == 'ai'" class="text text-15">
              {{ item.content.text }}
            </div>
            <!-- 图片 -->
            <div v-else-if="item.type == 'image'" class="image">
              <el-image
                style="max-width: 200px"
                :preview-src-list="[item.content.image]"
                :src="item.content.image"
                fit="cover"
              />
            </div>
            <!-- 位置 -->
            <locationMsg
              v-else-if="item.type === 'location'"
              :content="item.content"
              :is_my="item.is_my"
            />
            <!-- 自动回复 -->
            <autoResponseMsg
              v-else-if="item.type === 'keyword'"
              :content="item.content"
              :is_my="item.is_my"
            />
            <!-- 资讯 -->
            <articleMsg
              v-else-if="item.type === 'article'"
              :content="item.content"
              :is_my="item.is_my"
            />
            <!-- 文档 -->
            <fileMsg
              v-else-if="item.type === 'file'"
              :content="item.content"
              :is_my="item.is_my"
            />
            <!-- 问卷 -->
            <surveyMsg
              v-else-if="item.type === 'survey'"
              :content="item.content"
              :is_my="item.is_my"
              @openServiceDialog="openServiceDialog"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import day from '@/modules/consult/mixin/day'
import autoResponseMsg from '@/modules/consult/components/consult-service-workbench/msg-type/auto-response-msg/index.vue'
import surveyMsg from '@/modules/consult/components/consult-service-workbench/msg-type/surveyMsg.vue'
import articleMsg from '@/modules/consult/components/consult-service-workbench/msg-type/articleMsg.vue'
import fileMsg from '@/modules/consult/components/consult-service-workbench/msg-type/fileMsg.vue'
import locationMsg from '@/modules/consult/components/consult-service-workbench/msg-type/locationMsg.vue'
import tipsMsg from '@/modules/consult/components/consult-service-workbench/msg-type/tipsMsg.vue'
export default {
  mixins: [day],
  components: {
    autoResponseMsg,
    surveyMsg,
    articleMsg,
    fileMsg,
    locationMsg,
    tipsMsg,
  },
  props: {
    message: {
      type: Object,
      default: () => ({
        loading: false,
        noMore: false,
        form: {
          page_size: 20,
          mode: 'latest', // pre、latest
          id: '',
          chat_id: '',
          user_id: '',
          is_ai:0,
        },
        list: [],
      }),
    },
    leftAvatar: String,
    rightAvatar: String,
    customStyle: {
      type: Object,
      default:()=>({})
    }
  },
  methods: {
    messageScroll(e) {
      this.$emit('messageScroll', e)
    },
    openServiceDialog(chat_id) {
      this.$emit('openServiceDialog', chat_id)
    },
  },
}
</script>

<style lang="scss" scoped>
.message-list {
  height: calc(100% - 166px - 72px);
  width: 100%;
  padding: 20px;
  overflow: auto;
  .message-box {
    display: flex;
    padding: 8px 0;
    .message-content {
      max-width: calc(100% - 64px - 24px);
      position: relative;
      .time {
        color: rgba(203, 203, 203, 1);
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      .image {
        image {
          height: 100px;
        }
      }

      .text {
        line-height: 21px;
        font-weight: 400;
        word-break: break-all;
        min-height: 37px;
        border-radius: 4px;
        font-size: 14px;
        padding: 10px;
        position: relative;
        white-space: pre-line;
        display: inline-block;
      }
    }

    .withdraw {
      text-align: center;
      width: 100%;
      font-size: 12px;
      color: #b3b3b3;
      line-height: 18px;

      text {
        color: #1a75ff;
      }
    }
  }

  .message-left {
    .message-content {
      .text {
        background-color: #ffffff;
        color: #1a1a1a;
      }
    }
  }

  .message-right {
    flex-direction: row-reverse;
    .message-content {
      text-align: right;
      .text {
        text-align: left;
        background-color: rgba(26, 117, 255, 1);
        color: #fff;
      }
      .time {
      }
    }
  }
}
</style>
