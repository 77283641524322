import api from '@/base/utils/request'

export const getUserList = (data) => {
  return api({ url: '/admin/consult/workbench/index', method: 'post', data })
}
export const getMessages = (data) => {
  return api({ url: '/admin/consult/workbench/messages', method: 'post', data })
}
export const sendMessage = (data) => {
  return api({ url: '/admin/consult/workbench/send', method: 'post', data })
}
export const endService = (data) => {
  return api({ url: '/admin/consult/workbench/finish', method: 'post', data })
}
export const getUserInfo = (data) => {
  return api({ url: '/admin/consult/workbench/info', method: 'post', data })
}
export const getRemarks = (data) => {
  return api({
    url: '/admin/consult/workbench/getRemarks',
    method: 'post',
    data,
  })
}
export const saveUserTags = (data) => {
  return api({
    url: '/admin/consult/workbench/saveUserTags',
    method: 'post',
    data,
  })
}
export const records = (data) => {
  return api({ url: '/admin/consult/workbench/records', method: 'post', data })
}
export const updateQuestionStatus = (data) => {
  return api({
    url: '/admin/consult/workbench/updateQuestionStatus',
    method: 'post',
    data,
  })
}
export const addRemark = (data) => {
  return api({
    url: 'admin/consult/workbench/addRemark',
    method: 'post',
    data,
  })
}

export const readMessage = (data) => {
  return api({
    url: '/admin/consult/workbench/read',
    method: 'post',
    data,
  })
}

export const categories = () => {
  return api({
    url: 'admin/consult/workbench/categories',
    method: 'post',
  })
}
export const getAdminUsers = () => {
  return api({
    url: '/admin/consult/workbench/adminUsers',
    method: 'post',
  })
}

export const transferService = (data) => {
  return api({
    url: '/admin/consult/workbench/change',
    method: 'post',
    data
  })
}
export const getQuestions = (data) => {
  return api({
    url: 'admin/consult/workbench/questions',
    method: 'post',
    data
  })
}

export const start = (data) => {
  return api({
    url: '/admin/consult/workbench/start',
    method: 'post',
    data
  })
}
export const serviceCategory = (data) => {
  return api({
    url: '/admin/consult/serviceCategory/all',
    method: 'post',
    data
  })
}
