<template>
  <div class="survey-info __user-info-survey">
    <div
        class="user-info-survey-item"
        v-for="(item, index) in form_configs"
        :key="index"
    >
      <div class="label">{{ item.label }}：</div>

      <div v-if="typeof item.value === 'string'" class="value">{{ item.value }}</div>
      <div v-else-if="item.field === 'birthday'" class="value">{{ item.value }}</div>
      <div v-else-if="item.field === 'base-file-picker'" class="value flex-col">
        <ol style="padding-inline-start: 20px;">
          <li v-for="file in item.value" :key="file.name">
            <a :href="file.url" target="_blank">{{file.name || file.url}}</a>
          </li>
        </ol>
      </div>
      <div v-else-if="item.field === 'base-image-picker'" class="value grid-3">
        <template v-for="image in item.value">
        <el-image v-if="typeof image === 'string'" :preview-src-list="item.value" :key="image" :src="fixImageUrl(image, 500)"  fit="cover" />
        <el-image v-else :key="image.url" :preview-src-list="item.value.map(it => it.url)" :src="fixImageUrl(image.url, 500)" fit="cover" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'survey-info',
  props: {
    form_configs: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
.survey-info {

}

.__user-info-survey {
  width: 100%;
  /*overflow: auto;*/
  /*height: 500px;*/
  /*width: 500px;*/
  /*padding-left: 20px;*/
  padding-bottom: 24px;
  .user-info-survey-item {
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      min-height: 21px;
      word-wrap: break-word;
      word-break: break-all;
      color: rgba(128, 128, 128, 1);
      margin-bottom: 4px;
    }

    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      min-height: 21px;
      word-wrap: break-word;
      word-break: break-all;
      color: rgba(26, 26, 26, 1);
    }
  }

  .user-info-survey-item + .user-info-survey-item {

  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}
</style>