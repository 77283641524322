<template>
  <div @click="onClick" class="article-box">
    <div class="title">
      <div class="tag">
        <img class="tag-icon" src="../../../../assets/images/article-icon.png"/>
        <div class="tag-text">文章</div>
      </div>
      {{ config.title }}
    </div>

    <div class="content">
      <div class="flex-1 clamp-1">{{ config.category_name }}</div>
      <div class="flex flex-1 clamp-1" style="text-align: right"
        ><span style="margin-right: 20rpx;">{{ config.source_name }}&nbsp;&nbsp;</span>
        <span>{{ config.post_time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
    data_id: String
  },
  methods: {
    onClick() {
      // if (row.article_type) {
      //   this.$router.push({
      //     path: '/admin/article-admin/article/edit-wx-article',
      //     query: {
      //       id: id,
      //       portal_user_id: portal_user_id,
      //       come_from: 'ArticleList',
      //     },
      //   })
      // } else {
      //   this.$router.push({
      //     path: '/admin/article-admin/article/edit-article',
      //     query: {
      //       id: id,
      //       portal_user_id: portal_user_id,
      //       come_from: 'ArticleList',
      //     },
      //   })
      // }
      // this.jumpTo('path', { system_page: 'article_detail' }, { id: this.data_id })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-box {
  background: #f8f9fb;
  border: 0.5px solid #eff2f6;
  border-radius: 4px;
  padding: 8px;

  .title {
    line-height: 21px;
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 2px;
    @include clamp(2);
    .tag {
      background-color: #98c342;
      height: 17px;
      width: 37px;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .tag-icon {
        width:11px;
        height: 11rpx;
      }
      .tag-text {
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
  .content {
    height: 18px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #b5b8bf;
    font-size: 12px;
  }
  .clamp-1 {
    @include clamp(1);
  }
}
</style>
