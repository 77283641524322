<template>
  <div class="survey-box">
    <div class="title">
      {{ content.status ? '已填写问卷表单' : '未填写问卷表单' }}
    </div>
    <el-button :disabled="!content.status" type="primary" @click="openServiceDialog" plain> 查看问卷</el-button>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openServiceDialog() {
      if (this.content.status === 1) {
        this.$emit('openServiceDialog', this.content.chat_id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.survey-box {
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  background: #fff;
  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .btn {
    cursor: pointer;
    display: flex;
    width: 136px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: rgba(26, 117, 255, 0.05);
    color: rgba(26, 117, 255, 1);
    font-size: 14px;
  }
}
</style>
