<template>
    <div  class="keyword">{{ config.name||config.button_name }}</div>
 </template>
 
 <script>
 export default {
     props:{
         config:Object
     }
 }
 </script>
 
 <style lang="scss" scoped>
 .keyword {
     margin-top: 8px;
     height: 44px;
     font-size:13px;
     color: rgba(14, 117, 98, 1);
     border-radius: 4px;
     border: 1px solid rgba(14, 117, 98, 0.3);
     padding: 12px;
     background-color: #fff;
   }
 </style>