<template>
  <el-dialog :append-to-body="true" title="服务备注" :visible.sync="show">
    <div class="remarks" v-loading="loading">
      <el-empty
        v-if="remarks.length === 0 && !loading"
        description="暂无备注"
      ></el-empty>
      <template v-else>
        <div class="radio">
          排序：
          <el-radio-group v-model="reverse">
            <el-radio :label="true">从近到远</el-radio>
            <el-radio :label="false">从远到近</el-radio>
          </el-radio-group>
        </div>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(item, index) in remarks"
            :key="index"
            :timestamp="item.create_time"
            placement="top"
          >
            <el-card>
              <h4>{{ item.remark }}</h4>
              <p>
                {{ item.admin_user_nickname }} 提交于 {{ item.create_time }}
              </p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </template>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="add">添加备注</el-button>
    </span>
    <RemarkDialog
      v-model="showRemarkDialog"
      :updateList="getRemarks"
      :chat_id="chat_id"
      :user_id="user_id"
    ></RemarkDialog>
  </el-dialog>
</template>

<script>
import {
  getRemarks,
  addRemark,
} from '@/modules/consult/api/consult-service-workbench'
import RemarkDialog from './RemarkDialog'
export default {
  components: {
    RemarkDialog,
  },
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    chat_id: String,
    user_id: String,
    type:String,
  },
  watch: {
    show(val) {
      if (val) {
        this.getRemarks()
      }
    },
  },
  data() {
    return {
      remarks: [],
      loading: false,
      showRemarkDialog: false,
      reverse: true,
    }
  },

  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
  },
  methods: {
    getRemarks() {
      this.loading = true
      this.remarks = []
      getRemarks({
        chat_id:this.type === 'all'? '':this.chat_id,
        user_id: this.user_id,
      }).then((res) => {
        this.remarks = res.data.remarks
        this.loading = false
      })
    },
    add() {
      this.showRemarkDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.radio {
  margin-bottom: 20px;
}
.remarks {
  height: 50vh;
  overflow: auto;
  padding: 0 40px;
}
</style>
