<template>
  <div
    class="map-box"
    :class="[is_my === 1 ? 'right' : 'left']"
    @click.stop="getMap"
  >
    <div class="address">
      {{ content.address }}
    </div>
    <div v-if="content.name" class="name">{{ content.name }}</div>
    <div class="map">
      <baidu-map
        class="bmView"
        :center="{
          lat: marker.lat || 39.910924,
          lng: marker.lng || 116.413387,
        }"
        :zoom="12.8"
        :scroll-wheel-zoom="false"
        :map-click="false"
        @ready="loadMap"
      >
        <!-- <bm-local-search
          :keyword="
            marker.city + marker.detail
          "
          :auto-viewport="true"
          style="display: none"
          @searchcomplete="getLocation"
        >
        </bm-local-search> -->
        <bm-view
          :style="{
            width: '252px',
            marginTop: '4px',
            height: '162px',
            borderRadius: '4px',
          }"
        ></bm-view>
      </baidu-map>
    </div>
    <AddressSelector
      v-model="openAddressSelector"
      :detailForm="form"
      type="view"
    ></AddressSelector>
  </div>
</template>

<script>
import AddressSelector from '@/modules/consult/components/consult-service-workbench/AddressSelector.vue'
export default {
  components: {
    AddressSelector,
  },
  props: {
    content: Object,
    is_my: Number,
    // id:String
  },
  data() {
    return {
      openAddressSelector: false,
    }
  },
  computed: {
    marker() {
      return {
        lat: this.content.latitude,
        lng: this.content.longitude,
        width: 25,
        height: 25,
      }
    },
    form() {
      return {
        ...this.content,
        address_name: this.content.name,
      }
    },
  },
  methods: {
    loadMap(e) {
      console.log(e)
    },
    getMap() {
      this.openAddressSelector = true
    },
  },
}
</script>

<style lang="scss" scoped>
.map-box {
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  width: 268px;
  .address {
    color: #1a1a1a;
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name {
    color: #b3b3b3;
    font-size: 13px;
    line-height: 19.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .map {
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    .map-content {
      width: auto;
      border-radius: 6px;
      height: 155px;
      overflow: hidden;

      cover-view {
        border-radius: 6px;
        overflow: hidden;
      }
    }

    .map-content-view {
      width: auto;
      border-radius: 6px;
      height: 155px;
      position: absolute;
      top: 0px;
    }
  }
}
</style>
