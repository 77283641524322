<template>
  <el-dialog :append-to-body="true" title="聊天记录" :visible.sync="show">
    <div class="remarks">
      <MessageList
        ref="messageListRefs"
        style="
          width: 100%;
          height: 100%;
          background-color: rgba(248, 249, 251, 1);
        "
        :leftAvatar="leftAvatar"
        :rightAvatar="serviceConfig.avatar"
        :message="message"
        @messageScroll="messageScroll"
        @openServiceDialog="
          (chat_id) => openServiceDialog({ chat_id, user_id })
        "
      />
    </div>
    <SurveyListDialog
      :chat_id="surveyDialog.chat_id"
      :user_id="surveyDialog.user_id"
      v-model="surveyDialog.show"
    />
  </el-dialog>
</template>

<script>
import { getServiceConfig } from '@/modules/consult/api/consult-service/config'
import MessageList from '@/modules/consult/components/consult-service-workbench/MessageList/index.vue'
import MessageListMixin from '@/modules/consult/components/consult-service-workbench/MessageList/mixin.js'
import SurveyListDialog from '@/modules/consult/components/consult-service-workbench/SurveyListDialog.vue'
export default {
  mixins: [MessageListMixin],
  components: {
    MessageList,
    SurveyListDialog,
  },
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    chat_id: String,
    user_id: String,
    leftAvatar: String,
    is_ai: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 客服信息
      serviceConfig: {},
      //问卷信息
      surveyDialog: {
        show: false,
        user_id: '',
        chat_id: '',
      },
    }
  },
  watch: {
    show(val) {
      if (val) {
        console.log(this.chat_id, this.user_id)
        this.messageRefresh(
          {
            chat_id: this.chat_id,
            user_id: this.user_id,
            is_ai: this.is_ai,
          },
          () => {
            this.$nextTick(() => {
              const messageListRefs = this.$refs.messageListRefs.$el
              if (messageListRefs) {
                messageListRefs.scrollTop = messageListRefs.scrollHeight
              }
            })
          }
        )
        if (!this.serviceConfig.avatar) {
          this.getServiceConfig()
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
  },
  methods: {
    // 客服信息
    getServiceConfig() {
      getServiceConfig().then((res) => {
        this.serviceConfig = res.data
      })
    },
    // 问卷信息
    openServiceDialog({ chat_id, user_id }) {
      this.surveyDialog.show = true
      this.surveyDialog.user_id = user_id
      this.surveyDialog.chat_id = chat_id
    },
  },
}
</script>

<style lang="scss" scoped>
.radio {
  margin-bottom: 20px;
}
.remarks {
  height: 50vh;
  overflow: auto;
  padding: 0 40px;
}
</style>
