<template>
  <div
    class="file-box"
    :class="[is_my === 1 ? 'right' : 'left']"
    @click="checkFiles"
  >
    <img
      v-if="['xls', 'xlsx'].includes(content.url.replace(/.+\./, ''))"
      class="icon"
      src="@/modules/consult/assets/images/elex-icon.png"
    />
    <img
      v-else-if="['pdf'].includes(content.url.replace(/.+\./, ''))"
      class="icon"
      src="@/modules/consult/assets/images/pdf-icon.png"
    />
    <img
      v-else-if="['doc', 'docx'].includes(content.url.replace(/.+\./, ''))"
      class="icon"
      src="@/modules/consult/assets/images/word-icon.png"
    />
    <img
      v-else
      class="icon"
      src="@/modules/consult/assets/images/file-icon.png"
    />
    <span class="name">
      {{ content.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
    is_my: Number,
  },
  methods: {
    // 查看文件
    checkFiles() {
       console.log(this.content);
      // 预览文件
      if (this.content.type === 'image') {
        this.$previewImage([this.content.url])
      } else  {
        let url = this.content.url
        const a = document.createElement('a')
        a.href = url
        a.download = this.content.name // 下载后文件名
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click() // 点击下载
        document.body.removeChild(a)
      }
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.file-box {
  padding: 8px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  .icon {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .name {
    flex: 1;
    line-height: 21px;
    font-size: 14px;
    color: #1a75ff;
    @include clamp(2);
  }
}
</style>
