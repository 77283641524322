<template>
  <el-dialog
    class="dialog"
    :append-to-body="true"
    title="问卷表单"
    :visible.sync="show"
  >
    <div class="user-info-survey" v-loading="loading">
<!--      <div-->
<!--        class="user-info-survey-item"-->
<!--        v-for="(item, index) in list"-->
<!--        :key="index"-->
<!--      >-->
<!--        <div class="label">{{ item.label }}：</div>-->
<!--        <div class="value">{{ item.value }}</div>-->
<!--      </div>-->

      <survey-info :form_configs="list" />
      <el-empty
        v-if="list.length === 0 && !loading"
        description="暂无问卷表单"
      ></el-empty>
    </div>
  </el-dialog>
</template>

<script>
import { getUserInfo } from '@/modules/consult/api/consult-service-workbench'
import SurveyInfo from './SurveyInfo/SurveyInfo'

export default {
  components: { SurveyInfo },
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    chat_id: String,
    user_id: String,
  },
  watch: {
    show(val) {
      if (val) {
        this.getUserInfo()
      }
    },
  },
  data() {
    return {
      list: [],
      loading: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
  },
  methods: {
    getUserInfo() {
      this.loading = true
      this.remarks = []
      getUserInfo({
        chat_id: this.chat_id,
        user_id: this.user_id,
      }).then((res) => {
        this.list = res.data.survey.form_configs
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info-title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 12px;
}
.dialog {
  ::v-deep.el-dialog {
    width: 550px;
  }
}
.user-info-survey {
  overflow: auto;
  height: 500px;
  width: 500px;
  padding-left: 20px;
  .user-info-survey-item {
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      min-height: 21px;
      word-wrap: break-word;
      word-break: break-all;
      color: rgba(128, 128, 128, 1);
      margin-bottom: 4px;
    }
    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      min-height: 21px;
      word-wrap: break-word;
      word-break: break-all;
      color: rgba(26, 26, 26, 1);
    }
  }
  .user-info-survey-item + .user-info-survey-item {
    margin-top: 24px;
  }
}
</style>
