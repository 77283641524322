<template>
  <div @click="onClick" class="activity-box">
    <div class="title">
      <div class="tag">
        <img class="tag-icon" src="../../../../assets/images/activity-icon.png"/>
        <div class="tag-text">活动</div>
      </div>
      {{ config.name }}
    </div>
    <div class="address"
      ><img src="../../../../assets/images/address-icon.png" class="icon"/>
      <div class="text">{{ config.address_name }}</div></div
    >
    <div class="time">
      <img src="../../../../assets/images/time-icon.png" class="icon"/>
      <div class="text">{{ config.activity_time_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
    data_id: String
  },
  methods: {
    onClick() {
      // this.$router.push({
      //   name: "ActivityDetail",
      //   params: { type: "edit", id: this.data_id, create_step: 0 },
      // });
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-box {
  background: #f8f9fb;
  border: 0.5px solid #eff2f6;
  border-radius: 4px;
  padding:8px;

  .title {
    line-height: 21px;
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 2px;
    @include clamp(2);
    .tag {
      background-color: #ff6f31;
      height: 17px;
      width: 37px;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .tag-icon {
        width: 11px;
        height: 11px;
      }
      .tag-text {
        font-size: 10px;
        color: #ffffff;
      }
    }
  }

  .time,
  .address {
    height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 13px;
    color: #b5b8bf;
    .icon {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    .text {
      @include clamp(1);
    }
  }
}
</style>
