<template>
  <div class="link-box" @click="onClick">
    <div class="title">
      <div class="tag">
        <img class="tag-icon" src="../../../../assets/images/link-icon.png">
        <div class="tag-text">链接</div>
      </div>
      {{ config.jump_config.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object
  },
  methods: {
    onClick() {
      // this.jumpTo(this.config.jump_type, this.config.jump_config)
    }
  }
}
</script>

<style lang="scss" scoped>
.link-box {
  background: #f8f9fb;
  border: 0.5px solid #eff2f6;
  border-radius: 4px;
  padding: 8px;

  .title {
    line-height: 21px;
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 2px;
    @include clamp(2);
    .tag {
      background-color: #fc778e;
      height: 17px;
      width: 37px;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .tag-icon {
        width: 11px;
        height: 11px;
      }
      .tag-text {
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
}
</style>
