var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-box",class:[_vm.is_my === 1 ? 'right' : 'left'],on:{"click":function($event){$event.stopPropagation();return _vm.getMap.apply(null, arguments)}}},[_c('div',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.content.address)+" ")]),(_vm.content.name)?_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.content.name))]):_vm._e(),_c('div',{staticClass:"map"},[_c('baidu-map',{staticClass:"bmView",attrs:{"center":{
        lat: _vm.marker.lat || 39.910924,
        lng: _vm.marker.lng || 116.413387,
      },"zoom":12.8,"scroll-wheel-zoom":false,"map-click":false},on:{"ready":_vm.loadMap}},[_c('bm-view',{style:({
          width: '252px',
          marginTop: '4px',
          height: '162px',
          borderRadius: '4px',
        })})],1)],1),_c('AddressSelector',{attrs:{"detailForm":_vm.form,"type":"view"},model:{value:(_vm.openAddressSelector),callback:function ($$v) {_vm.openAddressSelector=$$v},expression:"openAddressSelector"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }