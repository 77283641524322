<template>
    <div @click="onClick" class="subject-box">
      <div class="title">
        <div class="tag">
          <img class="tag-icon" src="../../../../assets/images/subject-icon.png">
          <div class="tag-text">专题</div>
        </div>
        {{ config.name }}
      </div>
      <div class="content">
        {{ config.description }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      config: Object,
      data_id:String
    },
    methods:{
      onClick(){
        // this.jumpTo('page', {
        //   system_page: 'subject_detail'
        // }, {
        //   id: this.data_id
        // })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .subject-box {
    background: #f8f9fb;
    border: 0.5px solid #eff2f6;
    border-radius: 4px;
    padding: 8px;
  
    .title {
      line-height: 21px;
      font-size: 14px;
      color: #1a1a1a;
      padding: 0 2px;
      @include clamp(2);
      .tag {
        background-color: #54C7F1;
        height: 17px;
        width: 37px;
        border-radius: 3px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        .tag-icon {
          width: 11px;
          height: 11px;
        }
        .tag-text {
          font-size: 10px;
          color: #ffffff;
        }
      }
    }
    .content {
    //   height: 18px;
      margin-top: 8*2rpx;
 
      color: #b5b8bf;
      font-size: 12px;
      line-height: 18px;
      @include clamp(2);
    }
  }
  </style>
  