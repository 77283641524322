<template>
  <div class="activity-msg" :class="[is_my === 1 ? 'right' : 'left']">
    <!-- <image class="image" mode="aspectFill" :src="content.image.url" /> -->
    <el-image
      v-if="content.image.url"
      class="image"
      :src="content.image.url"
      fit="cover"
    ></el-image>
    <div class="title" v-if="content.image.url">资讯</div>
    <div class="title2">{{ content.title }}</div>
    <div class="bottom">
      <span class="source">{{ content.source_name }}</span>
      <span class="date">{{ content.post_time_text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
    is_my: Number,
  },
  methods: {
    // goDetail(item) {
    //   // #ifdef MP-WEIXIN
    //   const { jump_type, jump_config } = item.jump_link
    //   console.log(jump_type, jump_config)
    //   this.jumpTo(jump_type, { ...jump_config, appid: undefined })
    //   // #endif
    //   // #ifdef H5
    //   window.location.href = item.mp_link
    //   // #endif
    // },
  },
}
</script>

<style lang="scss" scoped>
.activity-msg {
  min-width: 177px;
  // height: 308 * 2rpx;
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;

  .title {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 12px;
    border-radius: 4px 0;
  }

  .image {
    width: 100%;
    height: 176px;
    border-radius: 6px;
    overflow: hidden;
  }

  .title2 {
    font-size: 14px;
    line-height: 21px;
    color: #000;
    @include clamp(2);
  }

  .bottom {
    @include clamp(1);
    color: #b3b3b3;
    font-size: 12px;
    .source {
      font-size: 12px;
      font-weight: 400;
      color: #b3b3b3;
      margin-right: 8px;
    }

    .date {
      font-size: 12px;
      font-weight: 400;
      color: #b3b3b3;
    }
  }
}
</style>
