<template>
  <div
    style="
      height: calc(100% - 65px);
      padding-bottom: 20px;
      overflow: hidden;
      min-height: 100%;
    "
  >
    <div class="tab-box">
      <el-tabs v-model="userInfo.tab">
        <el-tab-pane
          v-for="tab in userInfo.tabList"
          :key="tab.name"
          :label="`${tab.label}`"
          :name="tab.name"
        >
        </el-tab-pane
      ></el-tabs>
    </div>
    <div
      v-if="userInfo.tab === 'info'"
      v-loading="userInfo.infoLoading"
      class="loading"
    >
      <template v-if="userInfo.info">
        <div class="user-info-box">
          <div
            v-if="userInfo.info.chat.chat_type === 'question'"
            class="user-info-chat"
          >
            <div class="flex-align-center" style="margin-bottom: 16px">
              <div class="label">当前咨询类型：</div>
              <div class="tag">
                {{ userInfo.info.chat.category_name }}
              </div>
            </div>
            <div class="flex-align-center" style="margin-bottom: 16px">
              <div class="label">问题解决状态：</div>
              <el-select
                :disabled="userInfo.info.chat.status === 4 || admin_user !== 1"
                :value="userInfo.info.chat.question_status"
                placeholder="请选择"
                @change="questionStatusChange"
              >
                <el-option
                  v-for="item in questionStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              @click="
                openRemarksDialog({
                  chat_id: $props.chat_id,
                  user_id: $props.user_id,
                  type: 'all',
                })
              "
              style="width: 100%"
              type="primary"
              plain
              >服务备注</el-button
            >
          </div>
          <div class="user-info-user-list">
            <div class="user-info-title">用户信息</div>
            <div
              class="user-info-user-item"
              v-for="(item, index) in userInfo.info.user"
              :key="index"
            >
              <div class="label">{{ item.label }}：</div>
              <div
                v-if="item.field === 'base-image-picker'"
                class="value grid-3"
              >
                <template v-for="image in item.value">
                  <el-image
                    v-if="typeof image === 'string'"
                    :preview-src-list="item.value"
                    :key="image"
                    :src="fixImageUrl(image, 500)"
                    fit="cover"
                  />
                  <el-image
                    v-else
                    :key="image.url"
                    :preview-src-list="item.value.map((it) => it.url)"
                    :src="fixImageUrl(image.url, 500)"
                    fit="cover"
                  />
                </template>
              </div>
              <div v-else class="value">
                {{ item.value }}
              </div>
            </div>
          </div>
          <div class="user-info-tags">
            <div class="user-info-title">用户标签</div>
            <div class="tag-list">
              <el-tag
                :key="tag"
                v-for="tag in userInfo.info.tags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              >
              </el-input>
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput"
                >添加</el-button
              >
            </div>
          </div>
          <div v-if="userInfo.info.survey" class="user-info-survey">
            <div class="user-info-title">问卷表单</div>

            <survey-info :form_configs="userInfo.info.survey.form_configs" />
          </div>
        </div>
      </template>
    </div>
    <div
      v-else-if="userInfo.tab === 'records'"
      v-loading="userInfo.records.loading"
      class="loading records-box"
    >
      <div class="records-filter">
        <el-button
          @click="userInfo.records.showfilterDialog = true"
          style="width: 100%"
          type="primary"
          plain
          >筛选</el-button
        >
      </div>
      <div class="records-list" v-infinite-scroll="recordsScroll">
        <div
          class="records-item"
          v-for="item in userInfo.records.list"
          :key="item.id"
        >
          <div class="create-time">
            {{ item.create_time }}
          </div>
          <div class="question-status">
            <div class="label">咨询类型：</div>
            <div class="value">{{ item.category_name }}</div>
          </div>
          <div class="category-name">
            <div class="label">问题状态：</div>
            <el-tag v-if="item.question_status === 1" size="mini" type="success"
              >已解决</el-tag
            >
            <el-tag
              v-else-if="item.question_status === 3"
              size="mini"
              type="danger"
              >未解决</el-tag
            >
            <el-tag
              v-else-if="item.question_status === 2"
              size="mini"
              type="warning"
              >解决中</el-tag
            >
          </div>
          <div class="records-btn flex-align-center">
            <el-button
              class="flex-1"
              type="primary"
              size="mini"
              @click="
                openServiceDialog({
                  chat_id: item.chat_id,
                  user_id: item.user_id,
                })
              "
              plain
              >问题清单</el-button
            >
            <el-button
              class="flex-1"
              type="primary"
              size="mini"
              @click="
                openRemarksDialog({
                  chat_id: item.chat_id,
                  user_id: item.user_id,
                  type: 'appoint',
                })
              "
              plain
              >查看备注</el-button
            >
            <el-button
              class="flex-1"
              type="primary"
              size="mini"
              @click="
                openMessage({
                  chat_id: item.chat_id,
                  user_id: item.user_id,
                  is_ai: 0,
                })
              "
              plain
              >沟通记录</el-button
            >
          </div>
        </div> 
        <el-empty v-if="!userInfo.records.list.length&&!userInfo.records.loading" description="暂无跟进记录"></el-empty>
      </div>
    </div>
    <RecordsFilterDialog
      v-model="userInfo.records.showfilterDialog"
      :filterChange="rurecordsFilter"
      :recordsForm="userInfo.records.form"
    />

    <SurveyListDialog
      :chat_id="surveyDialog.chat_id"
      :user_id="surveyDialog.user_id"
      v-model="surveyDialog.show"
    />

    <RemarksListDialog
      :chat_id="remarksDialog.chat_id"
      :user_id="remarksDialog.user_id"
      :type="remarksDialog.type"
      v-model="remarksDialog.show"
    />
    <MessageListDialog
      :is_ai="messageListDialog.is_ai"
      v-model="messageListDialog.show"
      :chat_id="messageListDialog.chat_id"
      :user_id="messageListDialog.user_id"
      :leftAvatar="leftAvatar"
    />
  </div>
</template>

<script>
import {
  getUserInfo,
  saveUserTags,
  records,
  updateQuestionStatus,
} from '@/modules/consult/api/consult-service-workbench'
import day from '@/modules/consult/mixin/day'
import RemarksListDialog from '@/modules/consult/components/consult-service-workbench/RemarksListDialog.vue'
import SurveyListDialog from '@/modules/consult/components/consult-service-workbench/SurveyListDialog.vue'
import RecordsFilterDialog from '@/modules/consult/components/consult-service-workbench/RecordsFilterDialog.vue'
import SurveyInfo from '../SurveyInfo/SurveyInfo'
import MessageListDialog from '@/modules/consult/components/consult-service/consult-list/MessageListDialog.vue'
export default {
  mixins: [day],
  components: {
    SurveyInfo,
    RemarksListDialog,
    SurveyListDialog,
    RecordsFilterDialog,
    MessageListDialog,
  },
  props: {
    chat_id: String,
    user_id: String,
    status: {
      type: Number,
      default: 0,
    },
    admin_user: {
      type: Number,
      default: 0,
    },
    leftAvatar: String,
  },
  data() {
    return {
      userInfo: {
        // 用户资料
        info: null,
        // 资料加载
        infoLoading: false,
        // 备注
        remarks: null,

        tab: 'info',
        tabList: [
          {
            label: '资料',
            name: 'info',
          },
          {
            label: '跟进进度',
            name: 'records',
          },
        ],
        //跟进进度
        records: {
          form: {
            id: '', // 跟进列表的 ID，用 ID 实现分页
            page_size: 10,
            user_id: '',
            category_name: '', //资讯分类名
            question_status: -1, // -1 全部，0:未解决，1:已解决
            create_start_time: -1,
            create_end_time: -1,
          },
          loading: false,
          noMore: false,
          showfilterDialog: false,
          list: [],
        },
      },
      inputVisible: false,
      inputValue: '',
      remarksDialog: {
        show: false,
        user_id: '',
        chat_id: '',
        type: 'all',
      },

      //问卷信息
      surveyDialog: {
        show: false,
        user_id: '',
        chat_id: '',
      },
      questionStatusList: [
        {
          value: 3,
          label: '未解决',
        },
        {
          value: 2,
          label: '解决中',
        },
        {
          value: 1,
          label: '已解决',
        },
      ],
      // 消息弹窗
      messageListDialog: {
        show: false,
        chat_id: '',
        user_id: '',
        leftAvatar: '',
      },
    }
  },
  watch: {
    'userInfo.tab': {
      handler(val) {
        switch (val) {
          case 'info':
            this.userInfo.info = null
            this.getUserInfo()
            break
          case 'records':
            this.userInfo.records.form = {
              id: '', // 跟进列表的 ID，用 ID 实现分页
              page_size: 10,
              user_id: this.$props.user_id,
              category_name: '', //资讯分类名
              question_status: -1, // -1 全部，0:未解决，1:已解决
              create_start_time: -1,
              create_end_time: -1,
            }
            this.rurecordsRefresh()
            break
        }
      },
    },
    '$props.chat_id': {
      handler(val) {
        if (val) {
          this.userInfo.tab = ''
          this.$nextTick(() => {
            this.userInfo.tab = 'info'
          })
        }
      },
      immediate: true,
    },
    '$props.status': {
      handler(val) {
        if (this.userInfo.info && this.userInfo.info.chat) {
          this.userInfo.info.chat.status = val
        }
      },
    },
  },

  methods: {
    //消息记录
    openMessage(row) {
      this.messageListDialog.is_ai = row.is_ai
      this.messageListDialog.show = true
      this.messageListDialog.chat_id = row.chat_id
      this.messageListDialog.user_id = row.user_id
      this.messageListDialog.leftAvatar = ''
    },
    questionStatusChange(e) {
      this.updateQuestionStatus(e)
    },
    handleClose(tag) {
      this.userInfo.info.tags.splice(this.userInfo.info.tags.indexOf(tag), 1)
      this.saveUserTags()
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.userInfo.info.tags.push(inputValue)
        this.saveUserTags()
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 服务备注
    openRemarksDialog({ chat_id, user_id, type }) {
      this.remarksDialog.show = true
      this.remarksDialog.user_id = user_id
      this.remarksDialog.chat_id = chat_id
      this.remarksDialog.type = type
    },
    // 获取用户信息
    getUserInfo() {
      this.userInfo.infoLoading = true
      getUserInfo({
        chat_id: this.$props.chat_id,
        user_id: this.$props.user_id,
      })
        .then((res) => {
          this.userInfo.info = res.data
          this.$emit('updateUserInfoStatus', this.userInfo.info.chat)
          this.userInfo.infoLoading = false
        })
        .catch(() => {
          this.userInfo.infoLoading = false
        })
    },
    //保存用户标签
    saveUserTags() {
      saveUserTags({
        user_id: this.$props.user_id,
        tags: this.userInfo.info.tags,
      }).then((res) => {
        this.$message.success('保存成功')
      })
    },
    // 更新用户解决状态
    updateQuestionStatus(question_status) {
      updateQuestionStatus({
        user_id: this.$props.user_id,
        chat_id: this.$props.chat_id,
        question_status,
      }).then((res) => {
        this.$message.success('更新成功')
        this.userInfo.info.chat.question_status = question_status
      })
    },
    // 筛选
    rurecordsFilter(form) {
      this.userInfo.records.form = {
        ...form,
        id: '', // 跟进列表的 ID，用 ID 实现分页
        page_size: 10,
        user_id: this.$props.user_id,
      }
      this.userInfo.records.showfilterDialog = false
      this.rurecordsRefresh()
    },
    // 用户跟进进度刷新
    rurecordsRefresh() {
      this.userInfo.records.list = []
      this.userInfo.records.noMore = false
      this.userInfo.records.loading = false
      this.getRecordsList()
    },
    // 用户跟进进度滚动
    recordsScroll() {
      if (this.userInfo.records.loading || this.userInfo.records.noMore) {
        return
      }
      this.getRecordsList()
    },
    // 获取用户跟进记录
    getRecordsList() {
      this.userInfo.records.loading = true
      records(this.userInfo.records.form).then((res) => {
        this.userInfo.records.list.push(...res.data)
        if (res.data.length) {
          this.userInfo.records.form.id = res.data[res.data.length - 1].id
        }
        this.userInfo.records.loading = false
        if (res.data.length < this.userInfo.records.form.page_size) {
          this.userInfo.records.noMore = true
        }
      })
    },
    // 查看问题清单
    openServiceDialog({ chat_id, user_id }) {
      this.surveyDialog.show = true
      this.surveyDialog.user_id = user_id
      this.surveyDialog.chat_id = chat_id
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  height: 100%;
  overflow: hidden;
  position: relative;

  // padding-bottom: 65px; /* 因为有个 tab-box */
}
.tag-1 {
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  margin-left: 4px;
  font-size: 12px;
  background-color: rgba(17, 199, 96, 0.1);
  color: rgba(17, 199, 96, 1);
}
.tag-0 {
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  margin-left: 4px;
  font-size: 12px;
  background-color: rgba(255, 38, 71, 0.1);
  color: rgba(255, 38, 71, 1);
}
.tab-box {
  padding: 20px;
  padding-top: 16px;
  padding-bottom: 10px;
  ::v-deep.el-tabs__nav-wrap::after {
    background-color: transparent;
  }
  ::v-deep.el-tabs__item {
    color: #cbcbcb;
    font-weight: 400;
    width: calc(100% / 3);
    text-align: center;
    padding: 0px;
  }
  ::v-deep.el-tabs__item.is-active {
    color: #3576ff;
  }
  ::v-deep.el-tabs__nav {
    width: 100%;
  }
}
.user-info-box {
  height: calc(100% - 45px);
  overflow: auto;
  padding: 0 20px;

  .user-info-title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .user-info-chat {
    padding: 20px 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(239, 242, 246, 1);
    .label {
      min-width: 100px;
    }
  }
  .user-info-user-list {
    padding: 20px 0;
    .user-info-user-item {
      display: flex;
      font-size: 14px;
      line-height: 21px;
      .label {
        min-width: 90px;
        font-size: 14px;
        line-height: 21px;
        word-wrap: break-word;
        word-break: break-all;
      }
      .value {
        font-size: 14px;
        line-height: 21px;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    .user-info-user-item + .user-info-user-item {
      margin-top: 14px;
    }
  }
  .user-info-tags {
    margin-bottom: 24px;
    .user-info-tags-title {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .tag-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;

      border: 1px solid transparent;
      background: linear-gradient(white, white) padding-box,
        repeating-linear-gradient(
          -45deg,
          rgb(183, 190, 200) 0,
          #ccc 0.25em,
          white 0,
          white 0.5em
        );
    }
    .input-new-tag {
      width: 90px;
      vertical-align: bottom;
    }
  }
  .user-info-survey {
    .user-info-survey-item {
      .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        min-height: 21px;
        word-wrap: break-word;
        word-break: break-all;
        color: rgba(128, 128, 128, 1);
        margin-bottom: 4px;
      }
      .value {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        min-height: 21px;
        word-wrap: break-word;
        word-break: break-all;
        color: rgba(26, 26, 26, 1);
      }
    }
    .user-info-survey-item + .user-info-survey-item {
      margin-top: 24px;
    }
  }
}
.records-box {
  padding: 20px 0;
  .records-filter {
    padding: 0 20px;
    padding-bottom: 16px;
  }
  .records-list {
    overflow: auto;
    height: calc(100% - 80px);
    padding: 0 20px;
    .records-item {
      height: 173px;
      min-width: 270px;
      margin-bottom: 16px;
      border: 0.5px solid #dadfe6;
      border-radius: 4px;
      padding: 12px;
      .create-time {
        // height: 20px;
        display: flex;
        align-content: center;
        color: rgba(128, 128, 128, 1);
        font-size: 13px;
        font-weight: 400;
        padding-bottom: 13px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
      }
      .category-name,
      .question-status {
        display: flex;
        align-items: center;
        margin: 16px 0;
        height: 18px;

        .value,
        .label {
          font-weight: 400px;
          font-size: 12px;
          width: 72px;
        }
        .value {
          width: auto;
        }
      }
      .records-btn {
        // gap: 8px;
      }
    }
  }
}
</style>
