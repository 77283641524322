<template>
  <div @click="onClick" class="venue-box">
    <img class="venue-bg" mode="widthFix" :src="resUrl + '/consulting/venue-bg.png'">
    <div class="title">
      <div class="tag">
        <img class="tag-icon" src="../../../../assets/images/venue-icon.png">
        <div class="tag-text">场地</div>
      </div>
      {{ config.name }}
    </div>
    <div class="address"
      ><img src="../../../../assets/images/address-icon.png" class="icon">
      <div class="text">{{ config.address.address_name }} ({{ config.address.address }})</div></div
    >
    <div v-if="config.images&&config.images.length" class="images-list">
      <img v-for="(src, index) in config.images" mode="aspectFill" :key="index" :src="src" class="images-item">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
    data_id: String
  },
  data() {
    return {
      resUrl:window.serverConfig.VUE_APP_IMG_URL
    }
  },
  methods: {
    onClick() {
      // uni.navigateTo({
      //   url: `/sub/venue/pages/venue-detail?id=${this.data_id}`
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.venue-box {
  background: #f8f9fb;
  border: 0.5px solid #eff2f6;
  border-radius: 4px;
  padding: 8px;
  position: relative;
  .venue-bg{
    right: 0;
    width: 66%;
    top: 0;
    position: absolute;
  }
  .title {
    line-height: 21px;
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 2px;
    @include clamp(2);
    .tag {
      background-color: #ffc531;
      height: 17px;
      width: 37px;
      border-radius: 6rpx;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .tag-icon {
        width: 11px;
        height: 11px;
      }
      .tag-text {
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
  .images-list {
    margin-top: 8px;
    display: flex;
    width: 100%;
    height: 56px;
    gap: 8px;
    .images-item {
      width: calc(33% - 5px);
      height: 56px;
      border-radius: 2px;
      overflow: hidden;
    }
  }
  .time,
  .address {
    height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 13px;
    color: #b5b8bf;
    .icon {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    .text {
      @include clamp(1);
    }
  }
}
</style>
