<template>
  <div class="response-box" :class="[is_my === 1 ? 'right' : 'left']">
    <div
      class="response"
      :class="response.type"
      v-for="(response, index) in content"
      :key="index"
    >
      <div v-if="response.type === 'text'" v-text="response.config.text" class="text">
        
      </div>
      <!-- 关键词 -->
      <keywordMsg
        v-else-if="response.type === 'keyword' || response.type === 'service'"
        :config="response.config"
      />
      <!-- 文章 -->
      <articleMsg
        v-else-if="response.type === 'article'"
        :config="response.config"
        :data_id="response.data_id"
      />
      <!-- 活动 -->
      <activityMsg
        v-else-if="response.type === 'activity'"
        :config="response.config"
        :data_id="response.data_id"
      />
      <!-- 场地 -->
      <venueMsg
        v-else-if="response.type === 'venue'"
        :config="response.config"
        :data_id="response.data_id"
      />
      <!-- 链接 -->
      <linkMsg v-else-if="response.type === 'link'" :config="response.config" />
      <!-- 专题 -->
      <subjectMsg
        v-else-if="response.type === 'subject'"
        :config="response.config"
        :data_id="response.data_id"
      />
    </div>
  </div>
</template>

<script>
import keywordMsg from './keywordMsg'
import articleMsg from './articleMsg'
import activityMsg from './activityMsg'
import venueMsg from './venueMsg'
import linkMsg from './linkMsg'
import subjectMsg from './subjectMsg'
export default {
  components: {
    keywordMsg,
    articleMsg,
    activityMsg,
    venueMsg,
    linkMsg,
    subjectMsg,
  },
  props: {
    content: Array,
    is_my: Number,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.response-box {
  text-align: left;
  padding: 8px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  word-break: break-all;

  .text {
    font-size: 14px;
    line-height: 21px;
    color: #1a1a1a;
    white-space: pre-line;
  }
}
.title {
  font-size: 14px;
  color: rgba(128, 128, 128, 1);
  line-height: 18px;
  margin-bottom: 8px;
}
.response + .response {
  margin-top: 4px;
}
.keyword,
.article,
.venue,
.activity,
.link,
.subject,
.text {
  width: 280px;
 
}
.keyword,
.article,
.venue,
.activity,
.link,
.subject{
  cursor:pointer;
}
</style>
