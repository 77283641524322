import { getMessages } from '@/modules/consult/api/consult-service-workbench'
export default {
  data() {
    return {
      // 消息
      message: {
        loading: false,
        noMore: false,
        form: {
          page_size: 20,
          mode: 'latest', // pre、latest
          id: '',
          chat_id: '',
          user_id: '',
          is_ai: 0,
        },
        list: [],
      },
    }
  },
  methods: {
    // 消息列表滚动
    messageScroll() {
      let messageListRefs = this.$refs.messageListRefs.$el
      if (messageListRefs.scrollTop === 0) {
        this.getMessageList()
      }
    },
    // 消息列表刷新
    messageRefresh({ user_id, chat_id, is_ai = 0 }, callback) {
      console.log(user_id, chat_id, is_ai)
      this.message.loading = false
      this.message.noMore = false
      this.message.form.id = ''
      this.message.form.user_id = user_id
      this.message.form.chat_id = chat_id
      this.message.form.is_ai = is_ai
      this.message.form.mode = 'latest'
      // 先加载20条快点
      this.message.form.page_size = 20
      this.message.list = []
      this.getMessageList(callback)
    },
    // 获取消息列表
    getMessageList(callback) {
      if (this.message.loading || this.message.noMore) return
      this.message.loading = true
      getMessages(this.message.form)
        .then((res) => {
          const list = res.data.list.map((item) => {
            if (item.type === 'survey') {
              return {
                ...item,
                is_my: 0,
              }
            }
            return item
          })
          this.message.form.id = list[0].id
          this.message.list.unshift(...list)
          if (list.length < this.message.form.page_size) {
            this.message.noMore = true
          }
          let readMessage = list.reverse().find((item) => item.is_my === 0)
          // 消息列表回弹
          let messageListRefs = this.$refs.messageListRefs.$el
          let scrollHeight = 0
          if (messageListRefs) {
            scrollHeight = messageListRefs.scrollHeight
          }
          this.$nextTick(() => {
            if (!messageListRefs) {
              messageListRefs = this.$refs.messageListRefs.$el
            }
            messageListRefs.scrollTop =messageListRefs.scrollHeight - scrollHeight
            if(messageListRefs.scrollTop === 0){
              setTimeout(() => {
                messageListRefs.scrollTop = messageListRefs.scrollHeight - scrollHeight
              },500)
            }
          })
          // 消息列表初始化回调
          if (this.message.form.mode !== 'pre' && readMessage && callback) {
            callback(readMessage)
          }
          this.message.form.mode = 'pre'
          this.message.form.page_size = 50
          this.message.loading = false
        })
        .catch(() => {
          this.message.loading = false
        })
    },
  },
}
